<template>
  <el-form class="zwx-form edit-form" ref="msForm" :model="msForm" :rules="rules" label-position="right" label-width="82px">
    <base-headline title="基本信息" class="no-top" style="margin-bottom:20px;"></base-headline>
    <div class="edit-row">
      <el-form-item label="行政区划：" prop="busZoneCode">
        <zwx-select-area class="cascader-area-biz-zone" ref="bizZoneArea" @change="bizZoneChange" @loadOver="areaIdLoadOver()" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" :clearable="false" />
      </el-form-item>
      <el-form-item label="机构名称：" prop="unitName">
        <el-input class="zwx-input" v-model="msForm.unitName" placeholder="请输入" :maxlength="100" />
      </el-form-item>
      <el-form-item label-width="98px" label="社会信用代码：" prop="creditCode">
        <el-input class="zwx-input" v-model="msForm.creditCode" placeholder="请输入" />
      </el-form-item>
    </div>
    <div class="edit-row">
      <el-form-item label="机构编码：" prop="unitCode">
        <el-input class="zwx-input" v-model="msForm.unitCode" placeholder="请输入" :maxlength="30" />
      </el-form-item>
      <el-form-item label="注册日期：" prop="registerDate">
        <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" v-model="msForm.registerDate" type="date" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择" />
      </el-form-item>
      <el-form-item label-width="98px" label="法定代表人：" prop="legalPerson">
        <el-input class="zwx-input" v-model="msForm.legalPerson" placeholder="请输入" :maxlength="10" />
      </el-form-item>
    </div>
    <div class="edit-row">
      <el-form-item label="联系人：" prop="linkPerson">
        <el-input class="zwx-input" v-model="msForm.linkPerson" placeholder="请输入" :maxlength="10" />
      </el-form-item>
      <el-form-item label="联系电话：" prop="unitContact">
        <el-input class="zwx-input" v-model="msForm.unitContact" placeholder="请输入" />
      </el-form-item>
      <el-form-item label-width="98px" label="培训机构公章：" prop="annexList">
        <file-upload ref="picFileRef" accept=".png,.jpeg,.jpg,.JPG,.JPEG,.PNG," @showFileList="picFileShowFileList" @success="picFileUploadSuccess" :size="50 * 1024 * 1024" sizeInfo="50M">
          <el-button class="zwx-button zwx-button-text-26" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(msForm.annexList)">上传</el-button>
        </file-upload>
        <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(msForm.annexList)">
          <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview1(msForm.annexList, 0)">查看</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletionPicFile(0)">删除</el-button>
        </div>
      </el-form-item>
    </div>
    <div class="edit-row">
      <el-form-item label="管辖范围：" prop="areaAndTrainingAgencyRelationExpList">
        <el-cascader class="zwx-cascader theme-activity-zone" v-model="msForm.areaAndTrainingAgencyRelationExpList" :options="areaShowList" ref="ActivityZoneRef" :props="{
            multiple: true,
            checkStrictly: false,
          }" :show-all-levels="false" collapse-tags clearable @change="v => themeActivityZoneChange(v, 'ActivityZoneRef')"></el-cascader>
      </el-form-item>
      <el-form-item label="机构地址：" prop="busZoneCodeAddr">
        <el-input class="zwx-input" v-model="msForm.busZoneCodeAddr" placeholder="请输入" :maxlength="60" />
      </el-form-item>
      <el-form-item v-if="ifTrainingExamSplit" label="营业时间：" prop="tradeTime">
        <el-time-picker class="zwx-time-picker" is-range v-model="msForm.tradeTime" size="small" format="HH:mm" value-format="HH:mm" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围"></el-time-picker>
      </el-form-item>
      <el-form-item v-if="ifTrainingExamSplit" label="考试地址：" prop="examAddr">
        <el-input class="zwx-input" v-model="msForm.examAddr" placeholder="请输入" :maxlength="60" />
      </el-form-item>
    </div>

    <base-headline title="管理员" style="margin-bottom:20px;"></base-headline>
    <div class="edit-row">
      <el-form-item label="姓名：" prop="adminUserInfoExp.userName">
        <el-input class="zwx-input" v-model="msForm.adminUserInfoExp.userName" placeholder="请输入" :maxlength="10" />
      </el-form-item>
      <el-form-item label="手机号：" prop="adminUserInfoExp.mobileTel">
        <el-input class="zwx-input" v-model="msForm.adminUserInfoExp.mobileTel" placeholder="请输入" :maxlength="30" />
      </el-form-item>
    </div>
    <div class="edit-row">
      <el-form-item label="账号：" prop="adminUserInfoExp.userNo">
        <el-input class="zwx-input" v-model="msForm.adminUserInfoExp.userNo" placeholder="请输入" :maxlength="30" />
      </el-form-item>
      <el-form-item label="密码：" prop="adminUserInfoExp.password">
        <el-button v-if="$route.params.type == 'edit'" class="zwx-button zwx-button-32" type="primary" @click="resetPassword">初始化密码</el-button>
        <el-input v-else class="zwx-input" v-model="msForm.adminUserInfoExp.password" placeholder="请输入" :maxlength="20" />
      </el-form-item>
    </div>

    <app-config-table ref="appConfigTable" v-model="msForm.agencyPayBindExpList" :config="config" @add="addMerchant" @delete="deleteMerchant"></app-config-table>

    <invoice-config-table ref="invoiceConfigTable" v-model="msForm.agencyInvoiceBindExpList" :config="invoiceConfig" @add="addInvoice" @delete="deleteInvoice"></invoice-config-table>

    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="submit()">保存</el-button>
    </fixed-footer>
  </el-form>
</template>

<script>
import AppConfigTable from './AppConfigTable.vue'
import InvoiceConfigTable from './InvoiceConfigTable.vue'

function fetchDetail(uuid) {
  let data = {
    uuid,
  }
  this.$emit('loading', true)
  this.$system.get(
    this.api + '/training/platform/getTrainingAgency-1',
    data,
    true,
    true,
    data => {
      this.$emit('loading', false)
      if (data.type === '00') {
        if (uuid) {
          this.areaAndTrainingAgencyRelationExpList = data.trainingAgency.areaAndTrainingAgencyRelationExpList.map(i => ({ busZoneCode: i.busZoneCode, busZoneCodeName: i.busZoneCodeName }))
          data.trainingAgency.unitContact = this.$zwxSm.sm4JsDecrypt(data.trainingAgency.unitContact)
          data.trainingAgency.adminUserInfoExp.mobileTel = this.$zwxSm.sm4JsDecrypt(data.trainingAgency.adminUserInfoExp.mobileTel)
          data.trainingAgency.areaAndTrainingAgencyRelationExpList = data.trainingAgency.areaAndTrainingAgencyRelationExpList.map(i => [this.zoneCode12From, i.busZoneCode])
          if (data.trainingAgency.tradeTime) {
            data.trainingAgency.tradeTime = data.trainingAgency.tradeTime.split('-')
          }
          data.trainingAgency.annexList = []
          if (this.$zwxBase.verifyIsNotBlank(data.trainingAgency.agencySign)) {
            data.trainingAgency.annexList.push({
              fileIconShow: 'ms-file-jpg-icon',
              check: false,
              filePath: data.trainingAgency.agencySign,
            })
          }
          this.msForm = data.trainingAgency
          this.$refs.bizZoneArea.initSearch(data.trainingAgency.busZoneCode)
        }

        this.initPassWord = data.initPassWord
        this.config = data.agencyPayConfigMap
        this.invoiceConfig = data.agencyInvoiceConfigMap
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
    },
    () => {
      this.$emit('loading', false)
      this.$system.notify('错误', '网络连接失败', 'error')
    }
  )
}

export default {
  components: {
    AppConfigTable,
    InvoiceConfigTable,
  },
  data() {
    return {
      api: this.$store.state.api,
      rules: {
        busZoneCode: [{ required: true, message: '请选择行政区划', trigger: ['change', 'blur'] }],
        unitName: [{ required: true, message: '请输入机构名称', trigger: ['change', 'blur'] }],
        creditCode: [
          // { required: true, message: '请输入社会信用代码', trigger: ['change', 'blur'] },
          { required: false, validator: this.$validate.creditCode, trigger: ['change', 'blur'] },
        ],
        unitCode: [{ required: true, message: '请输入机构编码', trigger: ['change', 'blur'] }],
        // registerDate: [{ required: true, message: '请选择注册日期', trigger: ['change', 'blur'] }],
        legalPerson: [{ required: true, message: '请输入法定代表人', trigger: ['change', 'blur'] }],
        linkPerson: [{ required: true, message: '请输入联系人', trigger: ['change', 'blur'] }],
        unitContact: [
          { required: true, message: '请输入联系电话', trigger: ['change', 'blur'] },
          { required: true, validator: this.$validate.mbPeLdline, trigger: ['change', 'blur'] },
        ],
        areaAndTrainingAgencyRelationExpList: [{ required: true, message: '请选择管辖区域', trigger: ['change', 'blur'] }],
        busZoneCodeAddr: [{ required: true, message: '请输入机构地址', trigger: ['change', 'blur'] }],
        tradeTime: [{ required: true, message: '请选择营业时间', trigger: ['change', 'blur'] }],
        examAddr: [{ required: true, message: '请输入考试地址', trigger: ['change', 'blur'] }],
        adminUserInfoExp: {
          userName: [{ required: true, message: '请输入姓名', trigger: ['change', 'blur'] }],
          mobileTel: [
            { required: true, message: '请输入手机号', trigger: ['change', 'blur'] },
            { required: true, validator: this.$validate.mobilePhone, trigger: ['change', 'blur'] },
          ],
          userNo: [{ required: true, message: '请输入账号', trigger: ['change', 'blur'] }],
          password: [{ required: true, message: '请输入密码', trigger: ['change', 'blur'] }],
        },
        agencyPayBindExpList: [
          {
            // required: true,
            validator: (rule, value, callback) => {
              /**
               * 
              if (!value.length) {
                return callback(new Error('请添加商户信息'))
              }
               */
              // if (value.length > new Set(value.map(i => i.businessType)).size) {
              //   return callback(new Error('商户类型不能重复'))
              // }
              callback()
            },
            trigger: ['change', 'blur'],
          },
        ],
        /**
         * 
        agencyInvoiceBindExpList: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value.length) {
                return callback(new Error('请添加开票配置'))
              }
              callback()
            },
            trigger: ['change', 'blur'],
          },
        ],
         */
      },
      zoneCode12From: '',
      areaList: [],
      areaShowList: [],
      areaAndTrainingAgencyRelationExpList: [],
      config: {},
      invoiceConfig: {},
      systemConfig: {},
      initPassWord: '',
      msForm: {
        busZoneCode: '',
        unitName: '',
        creditCode: '',

        unitCode: '',
        registerDate: '',
        legalPerson: '',

        linkPerson: '',
        unitContact: '',
        areaAndTrainingAgencyRelationExpList: [],

        busZoneCodeAddr: '',
        tradeTime: undefined,
        examAddr: '',

        annexList: [],

        adminUserInfoExp: {
          userName: '',
          mobileTel: '',
          userNo: '',
          password: '',
        },

        agencyPayBindExpList: [],
        agencyInvoiceBindExpList: [],
      },
    }
  },
  computed: {
    ifTrainingExamSplit() {
      return window.top.vm.$store.state.config.ifTrainingExamSplit
    },
  },
  mounted() {
    this.$SimpleCodeTools.getConfig(data => {
      this.systemConfig = data
    })
    // this.search(1)
    // 赋值当前用户所属地区
    let config = window.top.vm.$store.state.config
    this.zoneCode12From = config.empower //'320200000000'
    // 获取地区码表
    this.$simpleCodeTools.getAreaList(this.zoneCode12From, 0, 3, data => {
      this.areaList = data
      let area = this.$simpleCodeTools.baseGetAreaSelect(data, 'zoneCode12')
      this.areaShowList = area.resultAreaList
    })
    this.$nextTick(() => {
      this.$refs.bizZoneArea.initSearch(this.zoneCode12From)
      this.$forceUpdate()
    })
    fetchDetail.call(this, this.$route.params.uuid)
  },
  methods: {
    /**
     * 单个文件预览
     */
    openFilePreview(fileName, filePath) {
      let previewFileList = []
      let item = {
        fileName: fileName,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    /**
     * 培训机构公章图片文件上传成功回调
     */
    picFileUploadSuccess(fileList) {
      // 文件集合
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.annexList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.msForm.annexList) {
          for (let j in fileList) {
            if (this.msForm.annexList[i].fileName === fileList[j].fileName) {
              this.msForm.annexList[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      /*this.msForm.trainingOptionList.forEach(item => {
        this.generateFilesUid(item.uuid)
      })*/
    },
    /**
     * 文件上传失败回调
     */
    picFileUploadSuccessError() {
      this.$system.notify('失败', '文件上传失败', 'error')
    },
    // 获取上传培训机构公章图片文件的UID
    generateFilesUidPic() {
      this.$emit('loading', true)
      let data = {
        folder: 'saas/' + this.systemConfig.domainPrefix + '/agency',
      }
      this.$system.get(
        this.api + '/systematic/tools/getAnnexUploadUid-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$refs.picFileRef.submit(data.annexUploadUid)
            this.$emit('loading', false)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        {}
      )
    },
    /**
     * 图片文件上传显示回调
     */
    picFileShowFileList(showList) {
      showList.forEach(item => {
        let flag = this.msForm.annexList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.msForm.annexList.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUidPic()
      }
    },
    /**
     * 删除题目图片文件
     */
    deletionPicFile(index) {
      let fileName = this.msForm.annexList[index].fileName
      let filePath = this.msForm.annexList[index].filePath
      this.msForm.annexList.splice(index, 1)
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.picFileRef.deleteIndex(index)
      }
      let fileList = this.$refs.picFileRef.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.picFileRef.deleteIndex(j)
            fileList = this.$refs.picFileRef.showFileList || []
            continue
          }
        }
      }
    },
    areaIdLoadOver() { },
    bizZoneChange() {
      this.msForm.busZoneCode = this.$refs.bizZoneArea.getChooseArea().zoneCode12
    },
    themeActivityZoneChange(v, value) {
      let checkedNodeList = this.$refs[value].getCheckedNodes()
      // checkedNodeList = checkedNodeList.filter(item => !(item.parent && item.parent.checked))
      checkedNodeList = checkedNodeList.filter(item => item.parent)
      this[value] = checkedNodeList
      let selectArr = []
      if (this.$zwxBase.verifyIsNotBlank(checkedNodeList)) {
        for (let i in checkedNodeList) {
          let data = checkedNodeList[i].data
          selectArr.push({ busZoneCode: data.value, busZoneCodeName: data.label })
        }
      }
      // this.$set(this.msForm, '_areaAndTrainingAgencyRelationExpList', selectArr)
      this.areaAndTrainingAgencyRelationExpList = selectArr
    },
    resetPassword() {
      this.msForm.adminUserInfoExp.password = this.initPassWord
      this.$system.notify('成功', '初始化密码成功', 'success')
    },
    addMerchant() {
      this.$refs.msForm.clearValidate('agencyPayBindExpList')
    },
    deleteMerchant() {
      this.$refs.msForm.clearValidate('agencyPayBindExpList')
    },
    addInvoice() {
      this.$refs.msForm.clearValidate('agencyInvoiceBindExpList')
    },
    deleteInvoice() {
      this.$refs.msForm.clearValidate('agencyInvoiceBindExpList')
    },
    submit() {
      this.$refs.msForm.validate(valid => {
        if (!valid) return
        const params = {
          ...this.msForm,
          ifTrainingExamSplit: this.ifTrainingExamSplit,
          unitContact: this.$zwxSm.sm4JsEncrypt(this.msForm.unitContact),
          areaAndTrainingAgencyRelationExpList: this.areaAndTrainingAgencyRelationExpList,
          tradeTime: this.msForm.tradeTime?.join('-'),
          agencySign: this.msForm.annexList.length > 0 ? this.msForm.annexList[0].filePath : '',
          adminUserInfoExp: {
            ...this.msForm.adminUserInfoExp,
            mobileTel: this.$zwxSm.sm4JsEncrypt(this.msForm.adminUserInfoExp.mobileTel),
            password: this.$route.params.type == 'edit' ? this.msForm.adminUserInfoExp.password : this.$zwxSm.md5(this.msForm.adminUserInfoExp.password),
          },
        }
        params.examOpenExplain = params.tradeTime
        this.$emit('loading', true)
        this.$system.postJson(
          this.api + '/training/platform/addOrModifyTrainingAgency-1',
          params,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.$system.notify('成功', data.mess, 'success')
              this.$router.push({ name: 'trainingInstitutionManagement', params: { fresh: true } })
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          () => {
            this.$emit('loading', false)
            this.$system.notify('错误', '网络连接失败', 'error')
          }
        )
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .zwx-input {
  width: 220px !important;
}
/deep/ .zwx-cascader {
  width: 220px !important;
  .el-input__inner {
    color: #191919 !important;
    padding-left: 6px !important;
    border: 1px solid #a8bbcf !important;
    border-radius: 2px !important;
  }
}
/deep/ .zwx-date-picker {
  width: 220px !important;
}
.zwx-time-picker {
  padding: 3px 6px;
  width: 220px;
  border: 1px solid #a8bbcf !important;
  border-radius: 2px !important;
  /deep/ .el-range-separator {
    width: unset;
  }
}
</style>
