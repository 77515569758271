var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        ref: "imageVerification",
        staticClass: "image-verification",
        style: { width: _vm.width },
        attrs: { id: "image-verification", onselectstart: "return false;" }
      },
      [
        _c("div", [
          _vm.containerSuccess
            ? _c(
                "div",
                { staticClass: "image-click-common container-success" },
                [
                  _c("i", { staticClass: "click-icon-success" }),
                  _c("span", [_vm._v("验证成功")])
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "image-click-varification image-click-common",
                  on: { click: _vm.imagePreviewVerification }
                },
                [
                  _c("i", { staticClass: "click-icon" }),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        !_vm.isViewIndex ? "点击完成验证" : "请完成安全验证"
                      )
                    )
                  ])
                ]
              )
        ]),
        _vm.isPreviewImage
          ? _c(
              "div",
              {
                staticClass: "preivew-image-border",
                style: { width: _vm.width },
                on: {
                  mouseleave: _vm.mouseleaveHandle,
                  mouseenter: function($event) {
                    $event.preventDefault()
                    return _vm.mouseEnterHandle.apply(null, arguments)
                  }
                }
              },
              [
                _c("div", { staticClass: "image-verification-valid" }, [
                  _c("img", {
                    staticClass: "max-image",
                    attrs: { src: _vm.imageMax }
                  }),
                  _c("img", {
                    staticClass: "mini-image",
                    style: {
                      top: _vm.scaleY + "px",
                      left: _vm.imgSilderLeft + "px"
                    },
                    attrs: {
                      src: _vm.imageMini,
                      width: _vm.miniWidth,
                      height: _vm.miniHeight
                    }
                  }),
                  _vm.isrefresh
                    ? _c("div", {
                        staticClass: "slide-verify-refresh-icon",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.refresh.apply(null, arguments)
                          }
                        }
                      })
                    : _vm._e()
                ]),
                _c(
                  "div",
                  {
                    staticClass: "picture-silder-verification",
                    class: {
                      containerActive: _vm.containerActive,
                      ccontainerFail: _vm.containerFail
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "slide-verify-slider-mask",
                        style: { width: _vm.sliderLeft + "px" },
                        attrs: { id: "slide-verify-slider-mask" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "slide-verify-slider-mask-item",
                            style: { left: _vm.sliderLeft + "px" },
                            attrs: { id: "slider-verify-slider" },
                            on: {
                              mousedown: _vm.sliderDown,
                              mouseleave: _vm.mouseLeaveIco
                            }
                          },
                          [
                            _c("div", {
                              staticClass: "slide-verify-slider-mask-item-icon"
                            })
                          ]
                        )
                      ]
                    ),
                    _c("span", { staticClass: "slide-verify-slider-text" }, [
                      _vm._v("向右拖动滑块完成验证")
                    ])
                  ]
                )
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }