var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "msForm",
      staticClass: "zwx-form edit-form",
      attrs: {
        model: _vm.msForm,
        rules: _vm.rules,
        "label-position": "right",
        "label-width": "82px"
      }
    },
    [
      _c("base-headline", {
        staticClass: "no-top",
        staticStyle: { "margin-bottom": "20px" },
        attrs: { title: "基本信息" }
      }),
      _c(
        "div",
        { staticClass: "edit-row" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "行政区划：", prop: "busZoneCode" } },
            [
              _c("zwx-select-area", {
                ref: "bizZoneArea",
                staticClass: "cascader-area-biz-zone",
                attrs: {
                  zoneCode12From: _vm.zoneCode12From,
                  zoneTypeMax: "0",
                  zoneTypeMin: "4",
                  showFullName: false,
                  clearable: false
                },
                on: {
                  change: _vm.bizZoneChange,
                  loadOver: function($event) {
                    return _vm.areaIdLoadOver()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "机构名称：", prop: "unitName" } },
            [
              _c("el-input", {
                staticClass: "zwx-input",
                attrs: { placeholder: "请输入", maxlength: 100 },
                model: {
                  value: _vm.msForm.unitName,
                  callback: function($$v) {
                    _vm.$set(_vm.msForm, "unitName", $$v)
                  },
                  expression: "msForm.unitName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "98px",
                label: "社会信用代码：",
                prop: "creditCode"
              }
            },
            [
              _c("el-input", {
                staticClass: "zwx-input",
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.msForm.creditCode,
                  callback: function($$v) {
                    _vm.$set(_vm.msForm, "creditCode", $$v)
                  },
                  expression: "msForm.creditCode"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "edit-row" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "机构编码：", prop: "unitCode" } },
            [
              _c("el-input", {
                staticClass: "zwx-input",
                attrs: { placeholder: "请输入", maxlength: 30 },
                model: {
                  value: _vm.msForm.unitCode,
                  callback: function($$v) {
                    _vm.$set(_vm.msForm, "unitCode", $$v)
                  },
                  expression: "msForm.unitCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "注册日期：", prop: "registerDate" } },
            [
              _c("el-date-picker", {
                staticClass: "zwx-date-picker",
                attrs: {
                  "popper-class": "zwx-date-picker-popper",
                  type: "date",
                  format: "yyyy-MM-dd HH:mm:ss",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  placeholder: "请选择"
                },
                model: {
                  value: _vm.msForm.registerDate,
                  callback: function($$v) {
                    _vm.$set(_vm.msForm, "registerDate", $$v)
                  },
                  expression: "msForm.registerDate"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "98px",
                label: "法定代表人：",
                prop: "legalPerson"
              }
            },
            [
              _c("el-input", {
                staticClass: "zwx-input",
                attrs: { placeholder: "请输入", maxlength: 10 },
                model: {
                  value: _vm.msForm.legalPerson,
                  callback: function($$v) {
                    _vm.$set(_vm.msForm, "legalPerson", $$v)
                  },
                  expression: "msForm.legalPerson"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "edit-row" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "联系人：", prop: "linkPerson" } },
            [
              _c("el-input", {
                staticClass: "zwx-input",
                attrs: { placeholder: "请输入", maxlength: 10 },
                model: {
                  value: _vm.msForm.linkPerson,
                  callback: function($$v) {
                    _vm.$set(_vm.msForm, "linkPerson", $$v)
                  },
                  expression: "msForm.linkPerson"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话：", prop: "unitContact" } },
            [
              _c("el-input", {
                staticClass: "zwx-input",
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.msForm.unitContact,
                  callback: function($$v) {
                    _vm.$set(_vm.msForm, "unitContact", $$v)
                  },
                  expression: "msForm.unitContact"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "98px",
                label: "培训机构公章：",
                prop: "annexList"
              }
            },
            [
              _c(
                "file-upload",
                {
                  ref: "picFileRef",
                  attrs: {
                    accept: ".png,.jpeg,.jpg,.JPG,.JPEG,.PNG,",
                    size: 50 * 1024 * 1024,
                    sizeInfo: "50M"
                  },
                  on: {
                    showFileList: _vm.picFileShowFileList,
                    success: _vm.picFileUploadSuccess
                  }
                },
                [
                  _vm.$zwxBase.verifyIsBlank(_vm.msForm.annexList)
                    ? _c(
                        "el-button",
                        {
                          staticClass: "zwx-button zwx-button-text-26",
                          attrs: { icon: "el-icon-upload2" }
                        },
                        [_vm._v("上传")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.$zwxBase.verifyIsNotBlank(_vm.msForm.annexList)
                ? _c(
                    "div",
                    { staticClass: "show-file" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "zwx-button zwx-button-text-26",
                          on: {
                            click: function($event) {
                              return _vm.openFilePreview1(
                                _vm.msForm.annexList,
                                0
                              )
                            }
                          }
                        },
                        [_vm._v("查看")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "zwx-button zwx-button-text-26",
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.deletionPicFile(0)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "edit-row" },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "管辖范围：",
                prop: "areaAndTrainingAgencyRelationExpList"
              }
            },
            [
              _c("el-cascader", {
                ref: "ActivityZoneRef",
                staticClass: "zwx-cascader theme-activity-zone",
                attrs: {
                  options: _vm.areaShowList,
                  props: {
                    multiple: true,
                    checkStrictly: false
                  },
                  "show-all-levels": false,
                  "collapse-tags": "",
                  clearable: ""
                },
                on: {
                  change: v => _vm.themeActivityZoneChange(v, "ActivityZoneRef")
                },
                model: {
                  value: _vm.msForm.areaAndTrainingAgencyRelationExpList,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.msForm,
                      "areaAndTrainingAgencyRelationExpList",
                      $$v
                    )
                  },
                  expression: "msForm.areaAndTrainingAgencyRelationExpList"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "机构地址：", prop: "busZoneCodeAddr" } },
            [
              _c("el-input", {
                staticClass: "zwx-input",
                attrs: { placeholder: "请输入", maxlength: 60 },
                model: {
                  value: _vm.msForm.busZoneCodeAddr,
                  callback: function($$v) {
                    _vm.$set(_vm.msForm, "busZoneCodeAddr", $$v)
                  },
                  expression: "msForm.busZoneCodeAddr"
                }
              })
            ],
            1
          ),
          _vm.ifTrainingExamSplit
            ? _c(
                "el-form-item",
                { attrs: { label: "营业时间：", prop: "tradeTime" } },
                [
                  _c("el-time-picker", {
                    staticClass: "zwx-time-picker",
                    attrs: {
                      "is-range": "",
                      size: "small",
                      format: "HH:mm",
                      "value-format": "HH:mm",
                      "range-separator": "至",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                      placeholder: "选择时间范围"
                    },
                    model: {
                      value: _vm.msForm.tradeTime,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "tradeTime", $$v)
                      },
                      expression: "msForm.tradeTime"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.ifTrainingExamSplit
            ? _c(
                "el-form-item",
                { attrs: { label: "考试地址：", prop: "examAddr" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: { placeholder: "请输入", maxlength: 60 },
                    model: {
                      value: _vm.msForm.examAddr,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "examAddr", $$v)
                      },
                      expression: "msForm.examAddr"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("base-headline", {
        staticStyle: { "margin-bottom": "20px" },
        attrs: { title: "管理员" }
      }),
      _c(
        "div",
        { staticClass: "edit-row" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "姓名：", prop: "adminUserInfoExp.userName" } },
            [
              _c("el-input", {
                staticClass: "zwx-input",
                attrs: { placeholder: "请输入", maxlength: 10 },
                model: {
                  value: _vm.msForm.adminUserInfoExp.userName,
                  callback: function($$v) {
                    _vm.$set(_vm.msForm.adminUserInfoExp, "userName", $$v)
                  },
                  expression: "msForm.adminUserInfoExp.userName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "手机号：", prop: "adminUserInfoExp.mobileTel" }
            },
            [
              _c("el-input", {
                staticClass: "zwx-input",
                attrs: { placeholder: "请输入", maxlength: 30 },
                model: {
                  value: _vm.msForm.adminUserInfoExp.mobileTel,
                  callback: function($$v) {
                    _vm.$set(_vm.msForm.adminUserInfoExp, "mobileTel", $$v)
                  },
                  expression: "msForm.adminUserInfoExp.mobileTel"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "edit-row" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "账号：", prop: "adminUserInfoExp.userNo" } },
            [
              _c("el-input", {
                staticClass: "zwx-input",
                attrs: { placeholder: "请输入", maxlength: 30 },
                model: {
                  value: _vm.msForm.adminUserInfoExp.userNo,
                  callback: function($$v) {
                    _vm.$set(_vm.msForm.adminUserInfoExp, "userNo", $$v)
                  },
                  expression: "msForm.adminUserInfoExp.userNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "密码：", prop: "adminUserInfoExp.password" } },
            [
              _vm.$route.params.type == "edit"
                ? _c(
                    "el-button",
                    {
                      staticClass: "zwx-button zwx-button-32",
                      attrs: { type: "primary" },
                      on: { click: _vm.resetPassword }
                    },
                    [_vm._v("初始化密码")]
                  )
                : _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: { placeholder: "请输入", maxlength: 20 },
                    model: {
                      value: _vm.msForm.adminUserInfoExp.password,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm.adminUserInfoExp, "password", $$v)
                      },
                      expression: "msForm.adminUserInfoExp.password"
                    }
                  })
            ],
            1
          )
        ],
        1
      ),
      _c("app-config-table", {
        ref: "appConfigTable",
        attrs: { config: _vm.config },
        on: { add: _vm.addMerchant, delete: _vm.deleteMerchant },
        model: {
          value: _vm.msForm.agencyPayBindExpList,
          callback: function($$v) {
            _vm.$set(_vm.msForm, "agencyPayBindExpList", $$v)
          },
          expression: "msForm.agencyPayBindExpList"
        }
      }),
      _c("invoice-config-table", {
        ref: "invoiceConfigTable",
        attrs: { config: _vm.invoiceConfig },
        on: { add: _vm.addInvoice, delete: _vm.deleteInvoice },
        model: {
          value: _vm.msForm.agencyInvoiceBindExpList,
          callback: function($$v) {
            _vm.$set(_vm.msForm, "agencyInvoiceBindExpList", $$v)
          },
          expression: "msForm.agencyInvoiceBindExpList"
        }
      }),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }