<template>
  <div>
    <div class="image-verification" id="image-verification" :style="{ width: width }" onselectstart="return false;" ref="imageVerification">
      <div>
        <div v-if="containerSuccess" class="image-click-common container-success">
          <i class="click-icon-success"></i>
          <span>验证成功</span>
        </div>
        <div v-else class="image-click-varification image-click-common" @click="imagePreviewVerification">
          <i class="click-icon"></i>
          <span>{{ !isViewIndex ? '点击完成验证' : '请完成安全验证' }}</span>
        </div>
      </div>
      <div v-if="isPreviewImage" class="preivew-image-border" :style="{ width: width }" @mouseleave="mouseleaveHandle" @mouseenter.prevent="mouseEnterHandle">
        <div class="image-verification-valid">
          <img class="max-image" :src="imageMax" />
          <img class="mini-image" :src="imageMini" :width="miniWidth" :height="miniHeight" :style="{ top: scaleY + 'px', left: imgSilderLeft + 'px' }" />
          <div v-if="isrefresh" @click.stop="refresh" class="slide-verify-refresh-icon"></div>
        </div>
        <div class="picture-silder-verification" :class="{ containerActive: containerActive, ccontainerFail: containerFail }">
          <div class="slide-verify-slider-mask" id="slide-verify-slider-mask" :style="{ width: sliderLeft + 'px' }">
            <div class="slide-verify-slider-mask-item" id="slider-verify-slider" :style="{ left: sliderLeft + 'px' }" @mousedown="sliderDown" @mouseleave="mouseLeaveIco">
              <div class="slide-verify-slider-mask-item-icon"></div>
            </div>
          </div>
          <span class="slide-verify-slider-text">向右拖动滑块完成验证</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'imageVerification',
  props: { uid: { type: String, default: '' }, width: { type: String, default: '290px' } },
  data() {
    return {
      api: this.$store.state.api,
      imageMax: '',
      imageMini: '',
      imagewidth: '',
      scalW: '',
      scaleY: '',
      miniWidth: '',
      miniHeight: '',
      imgSilderLeft: 0,
      sliderLeft: 0,
      isrefresh: true, //刷新按钮
      originX: '', //
      originY: '',
      isPreviewImage: false,
      isViewIndex: false,
      viewFlag: false,
      containerActive: false, // container active class
      containerSuccess: false, // container success class
      containerFail: false, // cont
      isMouseDown: false,
      x: '',
    }
  },
  watch: {
    /**
     * 监听图片验证成功 子传父
     */
    containerSuccess: function(value) {
      if (value) {
        var obj = {
          isSucess: value,
          x: this.x,
        }
        this.$emit('success', obj)
      }
    },
  },
  mounted() {
    this.bindEvents()
  },
  methods: {
    /**
     * 点击图片验证
     */
    imagePreviewVerification() {
      this.isPreviewImage = true
      this.isMouseDown = false
      this.reset()
    },

    /***
     * 监听鼠标事件*/
    bindEvents() {
      document.addEventListener('mousemove', e => {
        if (!this.isMouseDown) return
        e.stopPropagation()
        e.preventDefault()
        var moveX = e.clientX - this.originX
        if (moveX <= 0) {
          moveX = 0
        }

        if (moveX >= this.imagewidth - 50) {
          moveX = this.imagewidth - 50
        }
        var w = this.imagewidth - this.miniWidth
        this.sliderLeft = moveX
        this.imgSilderLeft = moveX > w ? w : moveX
        this.containerActive = true
      })

      document.addEventListener('mouseup', e => {
        this.isMouseDown = false
        if (this.sliderLeft === 0 && this.viewFlag) {
          this.isViewIndex = true
          this.isPreviewImage = false
          this.viewFlag = false
          return
        }
        if (this.sliderLeft && !this.viewFlag && !this.containerSuccess) {
          this.getImageMoveX()
          return
        }
        if (this.sliderLeft && this.viewFlag && !this.containerSuccess) {
          this.getImageMoveX()
          return
        }
      })
    },

    /**
     * 获取图片初始值
     */
    initImage() {
      this.getImage()
    },
    /**
     * @description 获取图片
     * @param uid
     */
    getImage() {
      var data = {
        uid: this.uid,
      }
      this.imagewidth = this.$refs['imageVerification'].offsetWidth - 20
      this.$system.get(this.api + '/systematic/puzzleImgVerifyCode-0', data, false, true, this.getImageSuccess, () => {
        this.$emit('error')
      })
    },
    getImageSuccess(res) {
      this.imageMax = res.image
      this.imageMini = res.smallImage
      var wid = res.imageWidth
      var hei = res.imageHeight
      var minWid = res.smallImageWidth
      var miniHei = res.smallImageHeight
      var scalMax = (wid / hei).toFixed(2)
      this.scalW = (wid / this.imagewidth).toFixed(2)
      var h = (this.imagewidth / scalMax).toFixed(2)
      var scalH = (hei / h).toFixed(2)
      this.miniWidth = Math.floor(minWid / this.scalW)
      this.miniHeight = Math.floor(miniHei / scalH)
      this.scaleY = Math.floor(res.y / scalH) + 0.5
      this.containerSuccess = false
    },
    getImageError() {},
    /**
     * 刷新验证图片
     */
    refresh() {
      this.initImage()
      this.reset()
    },
    /**
     * 获取向右滑动的距离
     */
    sliderDown(event) {
      event.preventDefault()
      this.originX = event.clientX
      this.isMouseDown = true
      this.viewFlag = false
    },
    mouseLeaveIco() {
      this.viewFlag = false
    },

    mouseEnterHandle(event) {
      event.stopPropagation()
      this.viewFlag = false
    },

    /**
     * 鼠标离开图片验证
     */
    mouseleaveHandle(event) {
      event.preventDefault()
      event.stopPropagation()
      this.viewFlag = true
    },
    /**
     * 后台验证图片移动距离
     */
    getImageMoveX() {
      this.x = parseInt(this.imgSilderLeft * this.scalW)
      var data = {
        uid: this.uid,
        x: this.x,
      }
      this.$system.post(this.api + '/systematic/puzzleImgCodeVerifyKey-0', data, false, true, this.getImageMoveXSuccess, this.getImageMoveXError)
    },
    getImageMoveXSuccess(res) {
      if (res.type == '00') {
        this.containerSuccess = true
        this.isPreviewImage = false
        this.isViewIndex = false
        this.isMouseDown = false
      } else {
        this.getImageMoveXError()
      }
    },
    getImageMoveXError() {
      this.isPreviewImage = true
      this.containerFail = true
      this.viewFlag = false
      this.failAction()
    },
    /**
     * 验证失败
     */
    failAction() {
      this.viewPicture = true
      setTimeout(() => {
        this.initImage()
        this.reset()
      }, 500)
    },
    /**
     * 重置
     */
    reset() {
      this.isViewIndex = false
      this.containerActive = false
      this.containerSuccess = false
      this.containerFail = false
      this.isMouseDown = false
      this.originX = 0
      this.sliderLeft = 0
      this.imgSilderLeft = 0
      this.x = 0
    },
  },
}
</script>
<style lang="less" scoped>
/** 图片验证组件 **/
.image-verification {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.image-verification .image-click-common {
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
}

.image-verification .image-click-varification {
  color: #4a536b;
  border: 1px solid #cccfda;
  box-sizing: border-box;
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-verification .image-click-varification .click-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  content: url(../../assets/ico/component/icon_yanzheng_normal.svg);
  margin-right: 6px;
}

.image-verification .image-click-varification:hover {
  color: #0d47a1;
}

.image-verification .image-click-varification:hover .click-icon {
  content: url(../../assets/ico/component/icon_shuttlebox_selected.svg);
}

.image-verification .container-success {
  color: #52cdbd;
  border: 1px solid #52cdbd;
  background-color: #d2f5ef !important;
  box-sizing: border-box;
}

.image-verification .container-success .click-icon-success {
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  content: url(../../assets/ico/component/icon_chenggong.svg);
  margin-right: 6px;
}

.image-verification .preivew-image-border {
  position: absolute;
  // width: 365px;
  // width: 290px;
  left: 0;
  bottom: 0;
  padding: 9px;
  padding-top: 14px;
  box-sizing: border-box;
  border: 1px solid #e4e7eb;
  border-radius: 2px;
  background-color: #fff;
  z-index: 2000;
}

.image-verification .image-verification-valid {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  z-index: 1000;
}

.image-verification .image-verification-valid .max-image {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
}

.image-verification .image-verification-valid .mini-image {
  display: block;
  height: auto;
  position: absolute;
  z-index: 2;
  // width: 76px;
}

.image-verification .picture-silder-verification {
  position: relative;
  text-align: center;
  height: 45px;
  line-height: 45px;
  background: #f8fafe;
  font-weight: 400;
  color: #4a536b;
  border: 1px solid #e9ecf2;
  border-radius: 2px;
  font-size: 14px;
}

.image-verification .slide-verify-slider-mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  border: 0 solid #457dff;
  background: #d7e4ff;
}

.image-verification .slide-verify-refresh-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
  height: 34px;
  cursor: pointer;
  background: url('../../assets/ico/component/icon_refresh.svg') no-repeat center;
  z-index: 5 !important;
}

.slide-verify-slider-mask-item {
  position: absolute;
}

.image-verification .slide-verify-slider-mask-item-icon {
  position: absolute;
  top: 18px;
  left: 15px;
  width: 14px;
  height: 12px;
  background: url('../../assets/ico/component/icon_arrow.svg') no-repeat;
}

.image-verification .slide-verify-slider-mask-item:hover {
  background: #1991fa;
}

.image-verification .slide-verify-slider-mask-item:hover {
  background: url('../../assets/ico/component/icon_arrow_white.svg') no-repeat;
  cursor: pointer;
}

/**滑块大小*/
.image-verification .containerActive .slide-verify-slider-mask-item {
  height: 43px;
  width: 45px;
  line-height: 43px;
  top: -1px;
  border: 1px solid #1991fa;
  background: #1991fa;
}

.image-verification .containerActive .slide-verify-slider-mask {
  height: 43px;
  border-width: 1px;
}

.image-verification .containerActive .slide-verify-slider-mask-item-icon {
  top: 18px;
  left: 15px;
  background: url('../../assets/ico/component/icon_arrow_white.svg') no-repeat;
}

.image-verification .ccontainerFail .slide-verify-slider-mask-item {
  height: 43px;
  width: 45px;
  top: -1px;
  border: 1px solid #f57a7a;
  background-color: #f57a7a !important;
}

.image-verification .ccontainerFail .slide-verify-slider-mask {
  height: 43px;
  border: 1px solid #e67b7b;
  background-color: #ffe8e8;
}

.image-verification .ccontainerFail .slide-verify-slider-mask-item-icon {
  top: 18px;
  left: 18px;
  background: url('../../assets/ico/component/icon_fail.svg') no-repeat !important;
}

.image-verification .containerActive .slide-verify-slider-text,
.image-verification .ccontainerFail .slide-verify-slider-text {
  display: none;
  font-size: 14px;
}
</style>
