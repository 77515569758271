<template>
  <div style="padding-bottom: 10px;border-top:1px solid #e1e5f4">
    <base-headline title="商户信息" class="no-top no-bottom">
      <el-button v-if="configList.length < 3" class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addInfo">添加</el-button>
    </base-headline>
    <el-form-item style="width:100%" label="" label-width="0" prop="agencyPayBindExpList">
      <el-table class="zwx-table" :data="configList" border stripe tooltip-effect="light">
        <el-table-column prop="index" label="序号" width="80" header-align="center" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="businessType" label="类型" width="200" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="`agencyPayBindExpList[${scope.$index}].businessType`" :rules="rules.businessType">
              <el-select class="zwx-select" popper-class="zwx-select-popper" v-model="scope.row.businessType" placeholder="请选择" @change="changeType(scope.row)">
                <el-option label="微信" value="04"></el-option>
                <el-option label="支付宝" value="01"></el-option>
                <el-option label="银联商务" value="05"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="payConfigUid" label="商户标题" width="400" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item>
              <el-select class="zwx-select" popper-class="zwx-select-popper" v-model="scope.row.payConfigUid" placeholder="请选择">
                <el-option v-for="item in configMap[scope.row.businessType]" :key="item.uuid" :label="item.payDescribe" :value="item.uuid"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="160" header-align="left" align="left">
          <template slot-scope="scope">
            <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deleteInfo(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'AppConfigTable',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      api: this.$store.state.api,
      // 校验规则
      rules: {
        businessType: [{ required: true, message: '请选择类型', trigger: ['change', 'blur'] }],
        // payConfigUid: [{ required: true, message: '请选择商户', trigger: ['change', 'blur'] }],
      },
      configList: [],
    }
  },
  computed: {
    configMap() {
      return {
        '04': (this.config.pay_04 || []).filter(v => v.appScenario == 1),
        '01': (this.config.pay_01 || []).filter(v => v.appScenario == 1),
        '05': (this.config.pay_05 || []).filter(v => v.appScenario == 1),
      }
    },
  },
  watch: {
    value: {
      handler(v) {
        this.configList = v
      },
      immediate: true,
    },
  },
  created() { },
  mounted() { },
  methods: {
    addInfo() {
      this.configList.push({
        businessType: undefined,
        payConfigUid: undefined,
      })
      this.$emit('change', this.configList)
      this.$emit('add')
    },
    changeType(row) {
      row.payConfigUid = undefined
    },
    deleteInfo(index) {
      this.configList.splice(index, 1)
      this.$emit('change', this.configList)
      this.$emit('delete')
    },
  },
}
</script>
